import AdhdImage from "../images/post2-img.png";
import ReadImage from "../images/post1-img.png";
import StoryImage from "../images/post3-img.webp";

import { useTranslation } from "react-i18next";

export default function BlogSection() {
  const { t } = useTranslation();
  const posts = [
    {
      id: 1,
      title: t("BlogSection.title1"),
      href: "/",
      description: t("BlogSection.desc1"),
      imageUrl: AdhdImage,
      date: "Aug 4, 2024",
      datetime: "2024-08-3",
      category: { title: t("BlogSection.specNeeds"), href: "#" },
    },
    {
      id: 2,
      title: t("BlogSection.title3"),
      href: "/preview",
      description: t("BlogSection.desc3"),
      imageUrl: StoryImage,

      date: "Sept 18, 2024",
      datetime: "2024-09-18",
      category: { title: t("BlogSection.preview"), href: "#" },
    },
    {
      id: 3,
      title: t("BlogSection.title2"),
      href: "/",
      description: t("BlogSection.desc2"),
      imageUrl: ReadImage,
      date: "Aug 2, 2024",
      datetime: "2024-08-02",
      category: { title: t("BlogSection.lifestyle"), href: "#" },
    },
  ];
  return (
    <div className="bg-white dark:bg-slate-900 py-24 sm:py-32 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="blur-0">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100  sm:text-4xl">
              {t("BlogSection.insights")}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-gray-300">
              {t("BlogSection.learnHowTo")}
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex flex-col items-start justify-between"
              >
                <div className="relative w-full">
                  <img
                    alt=""
                    src={post.imageUrl}
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <time dateTime={post.datetime} className="text-gray-500">
                      {post.date}
                    </time>
                    <a
                      // href={post.category.href}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:bg-slate-800 dark:hover:bg-slate-700"
                    >
                      {post.category.title}
                    </a>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 dark:text-gray-100 group-hover:text-gray-400">
                      {post.id === 2 ? (
                        <a href={post.href}>
                          <span className="absolute inset-0" />
                          {post.title}
                        </a>
                      ) : (
                        <a>
                          <span className="absolute inset-0" />
                          {post.title}
                        </a>
                      )}
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-300 dark:group-hover:text-gray-400">
                      {post.description}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

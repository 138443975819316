import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import PrivacyNotice from "../components/PrivacyNotice";

export default function LegalPage() {
  const { t } = useTranslation();

  // Function to get array from translation and ensure type
  const getArray = (key: string): string[] => {
    const data = t(key, { returnObjects: true });
    return Array.isArray(data) ? data : [];
  };

  // Privacy Policy
  const infoCollectList = getArray("LegalPage.infoCollectList");
  const infoCollectList2 = getArray("LegalPage.infoCollectList2");
  const dataProtectionRightsList = getArray(
    "LegalPage.dataProtectionRightsList",
  );
  const cookiesUsageList = getArray("LegalPage.cookiesUsageList");
  const cookieTypesList = getArray("LegalPage.cookieTypesList");
  const contactUsDetails = getArray("LegalPage.contactUsDetails");

  // Terms of Service
  const serviceTermsList = getArray("TermsOfServicePage.serviceTermsList");
  const userObligationsList = getArray(
    "TermsOfServicePage.userObligationsList",
  );
  const prohibitedActivitiesList = getArray(
    "TermsOfServicePage.prohibitedActivitiesList",
  );
  const disclaimersList = getArray("LegalPage.disclaimersList");
  const limitationsOfLiabilityList = getArray(
    "TermsOfServicePage.limitationsOfLiabilityList",
  );
  const disputeResolutionList = getArray(
    "TermsOfServicePage.disputeResolutionList",
  );
  const changesToTermsList = getArray("TermsOfServicePage.changesToTermsList");
  const contactUsTermsDetails = getArray(
    "TermsOfServicePage.contactUsTermsDetails",
  );

  return (
    <div>
      <Header />
      <PrivacyNotice />
      <div className="bg-white dark:bg-slate-900 px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            {t("LegalPage.privacyPolicy")}
          </h1>
          <p className="mt-2 text-sm text-gray-900 dark:text-gray-500">
            {t("LegalPage.revisionDate")} 11 Oct, 2024
          </p>

          <div className="mt-10 max-w-2xl">
            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.infoCollectionUse")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.infoCollectSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {infoCollectList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.infoCollectSection2")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {infoCollectList2.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.dataStorage")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.dataStorageSection")}
            </p>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.dataStorageSection1")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.marketing")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.marketingSection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.dataProtectionRights")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.dataProtectionRightsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {dataProtectionRightsList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.cookies")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.cookiesSection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.cookiesUsage")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.cookiesUsageSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {cookiesUsageList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.cookieTypes")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.cookieTypesSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {cookieTypesList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.manageCookies")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.manageCookiesSection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.thirdPartyLinks")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.thirdPartyLinksSection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.changesToPolicy")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.changesToPolicySection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.contactUs")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("LegalPage.contactUsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {contactUsDetails.map((detail, index) => (
                <li key={index} className="dark:text-gray-300">
                  {detail}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("LegalPage.contactAuthorities")}
            </h2>
            <p className="mt-6 border-b pb-10 text-gray-900 dark:text-gray-300">
              {t("LegalPage.contactAuthoritiesSection")}
            </p>

            {/* Terms of Service Section */}
            <h1 className="mt-20 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
              {t("TermsOfServicePage.termsOfService")}
            </h1>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.introduction")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.introductionSection")}
            </p>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.serviceTerms")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.serviceTermsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {serviceTermsList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.userObligations")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.userObligationsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {userObligationsList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.prohibitedActivities")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.prohibitedActivitiesSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {prohibitedActivitiesList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.disclaimers")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.disclaimersSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {disclaimersList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.limitationsOfLiability")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.limitationsOfLiabilitySection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {limitationsOfLiabilityList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.disputeResolution")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.disputeResolutionSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {disputeResolutionList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.changesToTerms")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.changesToTermsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {changesToTermsList.map((item, index) => (
                <li key={index} className="dark:text-gray-300">
                  {item}
                </li>
              ))}
            </ul>

            <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
              {t("TermsOfServicePage.contactUs")}
            </h2>
            <p className="mt-6 dark:text-gray-300">
              {t("TermsOfServicePage.contactUsSection")}
            </p>
            <ul className="mt-4 list-disc pl-6">
              {contactUsTermsDetails.map((detail, index) => (
                <li key={index} className="dark:text-gray-300">
                  {detail}
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-8">
            <p className="text-gray-400 text-sm">
              {t("TermsOfServicePage.originalStrength")}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

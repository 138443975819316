import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import PrivacyNotice from "../components/PrivacyNotice";
import FaqSection from "../components/FaqSection";

export default function DiscountPage() {
  const { t } = useTranslation();

  function OfferingDiscount() {
    return (
      <div className="bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
        <div className="bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <p className="text-base font-semibold leading-7 text-indigo-600">
              {t("DiscountPage.discount")}
            </p>
            <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-6xl">
              {t("DiscountPage.welcomeToLunarly")}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
              {t("DiscountPage.desc1")}
              <span className="font-semibold">{t("DiscountPage.desc2")}</span>
              <br />
              <br />
              {t("DiscountPage.desc3")}
            </p>

            <h3 className="mt-10 text-3xl font-semibold tracking-wide leading-7 text-indigo-500">
              NEWLUNARLY25
            </h3>

            <p className="mt-10 text-sm text-gray-400 dark:text-gray-600">
              {t("DiscountPage.notice")}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function NoDiscount() {
    return (
      <div className="bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
        <div className="bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <p className="text-base font-semibold leading-7 text-indigo-600">
              {t("DiscountPage.noDiscount")}
            </p>
            <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-6xl">
              {t("DiscountPage.noDiscountTitle")}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
              {t("DiscountPage.noDiscountDesc")}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <PrivacyNotice />
      <NoDiscount />
      <FaqSection />
      <Footer />
    </div>
  );
}

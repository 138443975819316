import { useTranslation } from "react-i18next";
import StoryImage from "../images/post3-img.webp";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import PrivacyNotice from "../components/PrivacyNotice";

export default function StoryPreview() {
  const { t } = useTranslation();

  // Access the story array from the translation data
  const story = t("PreviewPage.story", { returnObjects: true }) as string[]; // Type assertion

  return (
    <div>
      <Header />
      <PrivacyNotice />
      <div className="bg-white dark:bg-slate-900 px-6 py-10 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <figure className="mt-16">
            <img
              alt=""
              src={StoryImage}
              className="aspect-video rounded-xl bg-gray-50 object-cover w-full"
            />
          </figure>

          <h1 className="mt-12 text-3xl font-bold text-center tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            {t("PreviewPage.title")}
          </h1>

          <div className="mt-5">
            <span className="mr-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 dark:bg-yellow-400/10 dark:text-yellow-500 dark:ring-yellow-400/20">
              {t("PreviewPage.tagSimple")}
            </span>
            <span className="mr-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 dark:bg-green-500/10 dark:text-green-400 dark:ring-green-500/20">
              {t("PreviewPage.tagShort")}
            </span>
            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 dark:bg-blue-400/10 dark:text-blue-400 dark:ring-blue-400/30">
              {t("PreviewPage.tagFantasy")}
            </span>
          </div>

          <div className="mt-5 text-justify dark:text-gray-300">
            {story.map((paragraph, index) => (
              <p
                key={index}
                className={`mt-8 dark:text-gray-300 ${
                  index === story.length - 1 ? "font-semibold text-xl" : ""
                }`}
              >
                {paragraph}
              </p>
            ))}
          </div>

          <div className="mt-10">
            <Link
              to="/"
              className="text-md font-semibold leading-6 text-gray-900 dark:text-gray-100"
            >
              {t("PreviewPage.goHome")}
              <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

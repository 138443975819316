import { FormEvent, useState, useRef } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Field, Label, Switch } from "@headlessui/react";
import FaqSection from "../components/FaqSection";
import Header from "../components/Header";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";

import PrivacyNotice from "../components/PrivacyNotice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ContactPage() {
  const { t } = useTranslation();

  const [nameInput, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showErroModal, setShowErrorModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState<{
    name?: string;
    email?: string;
    message?: string;
    agree?: string;
  }>({});
  const formRef = useRef<HTMLFormElement>(null);

  const MSG_MAXLEN = 500;
  const NAME_MAXLEN = 50;

  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const validateForm = (): boolean => {
    const newErrors: {
      name?: string;
      email?: string;
      message?: string;
      agree?: string;
    } = {};
    const formElements = formRef.current?.elements as any;

    const name = formElements.name.value.trim();
    const email = formElements.email.value.trim();
    const message = formElements.message.value.trim();

    if (!name || name.length > NAME_MAXLEN) {
      newErrors.name = t("ContactPage.errorName");
    }

    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = t("ContactPage.errorEmail");
    }

    if (!message || message.length > MSG_MAXLEN) {
      newErrors.message = t("ContactPage.errorMessage");
    }

    if (!agreed) {
      newErrors.agree = t("ContactPage.errorPolicy");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm() || loading) {
      setLoading(false);
      return;
    }

    if (formRef.current) {
      emailjs
        .sendForm("service_6dwkrle", "template_xr8ichc", formRef.current, {
          publicKey: "OGAVSpLzTEi54AtUa",
        })
        .then(
          () => {
            setShowModal(true);

            setMessage("");
            setEmail("");
            setName("");
          },
          (error: any) => {
            setShowErrorModal(true);
          },
        );
    }

    setLoading(false);
  };

  const ErrorAlert = () => {
    return (
      <div className="rounded-md bg-red-50 dark:bg-red-500/20 p-4 mt-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800 dark:text-red-500">
              {t("ContactPage.errorTitle")}
            </h3>
            <div className="mt-2 text-sm text-red-700 dark:text-red-600">
              <p>{t("ContactPage.errorMsg")}</p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  type="button"
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                  className="rounded-md dark:bg-red-800/10 dark:hover:bg-red-700/20 bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50 dark:text-red-500 dark:focus:ring-offset-red-800"
                >
                  {t("ContactPage.dismiss")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SucessAlert = () => {
    return (
      <div className="rounded-md bg-green-50 dark:bg-green-500/20 p-4 mt-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-green-400"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800 dark:text-green-500">
              {t("ContactPage.thankYou")}
            </h3>
            <div className="mt-2 text-sm text-green-700 dark:text-green-600">
              <p>{t("ContactPage.emailMsg")}</p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="rounded-md dark:bg-green-800/10 dark:hover:bg-green-700/20 bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50 dark:text-green-500 dark:focus:ring-offset-green-800"
                >
                  {t("ContactPage.dismiss")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <PrivacyNotice />

      <div className="isolate bg-white dark:bg-slate-900 px-6 py-24 sm:py-32 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
            {t("ContactPage.contactUs")}
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 dark:text-gray-300">
            {t("ContactPage.description")}
          </p>
        </div>
        <form
          ref={formRef}
          onSubmit={submitEmail}
          className="mx-auto mt-16 max-w-xl sm:mt-20"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
              >
                {t("ContactPage.name")}
                <span className="text-red-500 font-semibold text-sm">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  id="name"
                  disabled={loading}
                  name="user_name"
                  type="text"
                  autoComplete="name"
                  value={nameInput}
                  onChange={handleNameChange}
                  maxLength={NAME_MAXLEN}
                  className="block dark:bg-slate-700 dark:ring-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex justify-between">
                  <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                  <p className="flex justify-end text-gray-600 text-sm mt-1">
                    {nameInput.length} / {NAME_MAXLEN}
                  </p>
                </div>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
              >
                Email
                <span className="text-red-500 font-semibold text-sm">*</span>
              </label>
              <div className="mt-2.5">
                <input
                  id="email"
                  disabled={loading}
                  name="user_email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="block dark:bg-slate-700 dark:ring-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
              >
                {t("ContactPage.message")}
                <span className="text-red-500 font-semibold text-sm">*</span>
              </label>
              <div className="mt-2.5">
                <textarea
                  value={message}
                  id="message"
                  name="message"
                  rows={4}
                  disabled={loading}
                  onChange={handleMessageChange}
                  maxLength={MSG_MAXLEN}
                  className="block dark:bg-slate-700 dark:ring-slate-600 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex justify-between">
                  <p className="text-red-500 text-sm mt-1">{errors.message}</p>
                  <p className="flex justify-end text-gray-600 text-sm mt-1">
                    {message.length} / {MSG_MAXLEN}
                  </p>
                </div>
              </div>
            </div>
            <Field className="flex gap-x-4 sm:col-span-2">
              <div className="flex h-6 items-center">
                <Switch
                  checked={agreed}
                  disabled={loading}
                  onChange={setAgreed}
                  className="group flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                >
                  <span className="sr-only">
                    {t("ContactPage.agreeToPolicies")}
                  </span>
                  <span
                    aria-hidden="true"
                    className="h-4 w-4 transform rounded-full bg-white dark:bg-slate-900 shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                  />
                </Switch>
              </div>
              <Label className="text-sm leading-6 text-gray-600 dark:text-gray-300">
                {t("ContactPage.youAgree")}{" "}
                <Link to="/legal" className="font-semibold text-indigo-500">
                  {t("ContactPage.privacyPolicy")}
                </Link>
                .<span className="text-red-500 font-semibold text-sm">*</span>
              </Label>
              {errors.agree && (
                <p className="text-red-500 text-sm mt-1">{errors.agree}</p>
              )}
            </Field>
          </div>

          <div className="mt-10">
            <button
              type="submit"
              disabled={loading}
              className="w-full flex flex-row justify-center items-center rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 animate-spin"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                  />
                </svg>
              ) : (
                <>{t("ContactPage.submit")}</>
              )}
            </button>
          </div>

          {showErroModal && <ErrorAlert />}
          {showModal && <SucessAlert />}
        </form>
        <FaqSection />
      </div>

      <Footer />
    </div>
  );
}
